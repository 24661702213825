<template>
  <div
    :class="['button-widget', { massEditBtns: value === 'removeAll' && value === 'editAllModal' }]"
  >
    <button type="button" disabled="disabled" v-if="loading">
      <span aria-hidden="true" class="spinner-border"> </span>
      Загрузка...
    </button>
    <!--TODO: ВСТАВИТЬ ПОСЛЕ ТЕСТА!-->
    <!--v-else-if="!loading && roleHidden"-->
    <button
      v-else-if="!loading"
      type="button"
      :title="tooltip"
      :disabled="disabledButton"
      @click="Button__click"
      :class="{
        outlined: outlined,
        deleteButton: value === 'deleteButton',
        removeAll: value === 'removeAll',
        editAll: value === 'editAllModal',
        filterSearch: value === 'filterSearch',
        filterClear: value === 'filterClear'
      }"
    >
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="value === 'createEventModal'"
      >
        <path
          d="M8.28092 16.5C8.09835 16.5 7.95229 16.4486 7.84275 16.3459C7.73321 16.2432 7.67844 16.1062 7.67844 15.935V9.16774H0.602478C0.419909 9.16774 0.273854 9.11637 0.164312 9.01364C0.0547708 8.91092 0 8.77394 0 8.60273V8.24318C0 8.07196 0.0547708 7.93499 0.164312 7.83226C0.273854 7.72954 0.419909 7.67817 0.602478 7.67817H7.67844V1.06501C7.67844 0.893794 7.73321 0.756822 7.84275 0.654093C7.95229 0.551364 8.09835 0.5 8.28092 0.5H8.6917C8.87427 0.5 9.02032 0.551364 9.12986 0.654093C9.2394 0.756822 9.29418 0.893794 9.29418 1.06501V7.67817H16.3975C16.5801 7.67817 16.7261 7.72954 16.8357 7.83226C16.9452 7.93499 17 8.07196 17 8.24318V8.60273C17 8.77394 16.9452 8.91092 16.8357 9.01364C16.7261 9.11637 16.5801 9.16774 16.3975 9.16774H9.29418V15.935C9.29418 16.1062 9.2394 16.2432 9.12986 16.3459C9.02032 16.4486 8.87427 16.5 8.6917 16.5H8.28092Z"
          fill="#FAFAFF"
        />
      </svg>
      <img
        src="~@/assets/img/searchFilterIcon.svg"
        v-if="value === 'filterSearch'"
        class="filter-search-icon"
      />
      <img
        src="~@/assets/img/editDisabled.svg"
        v-if="value === 'editAllModal' && !disabledButton"
        class="mass-edit-btn editActive"
      />
      <img
        src="~@/assets/img/editHover.svg"
        v-if="value === 'editAllModal' && !disabledButton"
        class="mass-edit-btn editActive"
      />
      <img
        src="~@/assets/img/delDisabled.svg"
        v-if="value === 'removeAll' && !disabledButton"
        class="mass-edit-btn delActive"
      />
      <img
        src="~@/assets/img/delHover.svg"
        v-if="value === 'removeAll' && !disabledButton"
        class="mass-edit-btn delActive"
      />
      <img
        src="~@/assets/img/editDisabled.svg"
        v-if="value === 'editAllModal' && disabledButton"
        class="mass-edit-btn"
      />
      <img
        src="~@/assets/img/delDisabled.svg"
        v-if="value === 'removeAll' && disabledButton"
        class="mass-edit-btn"
      />
      <img src="~@/assets/img/addIcon.svg" v-if="value === 'add'" class="mass-edit-btn" />
      <span>{{ label }}</span>

      <img width="9px" v-if="arrow && !outlined" src="~@/assets/img/ButtonArrow.svg" alt="arrow" />
      <img
        width="9px"
        v-if="arrow && outlined && !disabledButton"
        src="~@/assets/img/ButtonArrowOutlined.svg"
        alt="arrow"
      />
    </button>
  </div>
</template>

<script>
import { notEmpty } from "@/helpers/helperFunction";

export default {
  props: [
    "label",
    "value",
    "tooltip",
    "loading",
    "outlined",
    "readonly",
    "hidden",
    "role",
    "pk",
    "model",
    "lg",
    "arrow",
    "unique"
  ],
  name: "Button",
  mounted() {
    this.$root.$on("setLoading", (state, value) => {
      if (value === this.value) {
        this.loading = state;
      }
    });
  },
  methods: {
    Button__click(event) {
      let data = {
        eventName: "Button__click",
        data: {
          event: event,
          value: this.value,
          label: this.label,
          items: this.items,
          loading: this.loading,
          pk: this.pk,
          model: this.model,
          role: this.role,
          hidden: this.hidden
        }
      };
      this.$root.$emit(`button:click_${this.unique}`);
      this.$emit("button_clicked");
    }
  },
  computed: {
    /**
     * Блокировка кнопки в случае не пройденной валидации
     * Проверяется на основе метаДанных из правил существующих валидаций
     * @return {boolean}
     **/
    // eslint-disable-next-line vue/return-in-computed-property
    disabledButton() {
      if (this.readonly) {
        return this.readonly;
      } else if (
        this.value === "save" ||
        this.value === "nextStep" ||
        this.value === "saveAndAdd"
      ) {
        let route =
          this.$route.params.model ||
          this.$route.path
            .split(/\s+/)
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(" ")
            .split("/")[1];

        let currentPath = this.$store.state["metaProjectData"]["monolit"][route].fields;
        if (this.$store.state["metaProjectData"]["monolit"] !== null) {
          if (this.$store.state.newRecord) {
            for (let keys in currentPath) {
              if (currentPath[keys].required === true) {
                if (!this.$store.state.newRecord[keys] || this.$store.state.newRecord[keys] === 0) {
                  return true;
                }
              }
              if (currentPath[keys].type === "int") {
                return false;
              }
              if (currentPath[keys].min && this.$store.state.newRecord[keys]) {
                if (currentPath[keys].min > this.$store.state.newRecord[keys].length) {
                  return true;
                }
              }
              if (currentPath[keys].max && this.$store.state.newRecord[keys]) {
                if (currentPath[keys].max < this.$store.state.newRecord[keys].length) {
                  return true;
                }
              }
            }
          }
        }
      } else if (this.value === "editAll") {
        return Object.keys(this.$store.state.newRecord).length <= 0;
      } else {
        return false;
      }
    },
    /**
     * Скрытие кнопок по endpoint'am из МетаДанных приложения
     */
    // eslint-disable-next-line vue/return-in-computed-property
    roleHidden() {
      if (this.role) {
        let route =
          this.$route.params.model ||
          this.$route.path
            .slice(1)
            .split(/\s+/)
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(" ")
            .split("/")[1];
        if (this.$store.state.auth.endpoints["monolit"]) {
          if (notEmpty(this.$store.state.auth.endpoints["monolit"][route])) {
            for (let i in this.$store.state.auth.endpoints["monolit"][route]) {
              if (this.role === this.$store.state.auth.endpoints["monolit"][route][i]) {
                return true;
              }
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";

.filter-search-icon {
  margin-right: 6px;
}
button .editActive:last-child {
  display: none;
}
button:hover .editActive:last-child {
  display: inline;
}
button:hover .editActive:first-child {
  display: none;
}
button .delActive:last-child {
  display: none;
}
button:hover .delActive:last-child {
  display: inline;
}
button:hover .delActive:first-child {
  display: none;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 32px;
  border-radius: 3px;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  transition: 0.2s;
  background-color: $color-interactive;
  color: $color-charts-grey-3;
  -moz-appearance: none !important;
  img {
    margin-right: 6px !important;
    width: 14px;
    height: 14px;
  }
  svg {
    margin-right: 13px;
  }
  span {
    margin-top: 2px;
  }
}
button:hover {
  background-color: $color-button-hover;
}
button:active {
  background-color: $color-button-click;
}
button:disabled {
  background-color: $color-disabled;
}
.outlined {
  color: $color-interactive;
  background-color: transparent;
  border: 1px solid $color-interactive;
}
.outlined:hover {
  background-color: $color-button-hover;
  color: $color-charts-grey-3;
}
.outlined:active {
  background-color: $color-button-click;
}
.outlined:disabled {
  border-color: $color-disabled;
  background-color: transparent;
  color: $color-disabled;
}
.deleteButton {
  color: $color-charts-grey-3;
  background-color: $color-charts-red-1;
  border: 1px solid $color-charts-red-1;
}
.deleteButton:hover {
  background-color: $color-button-disable-hover;
  color: $color-charts-grey-3;
}
.deleteButton:active {
  background-color: $color-button-disable-click;
}
.deleteButton:disabled {
  border-color: $color-disabled;
  background-color: $color-disabled;
  color: $color-charts-grey-3;
}
.filterSearch {
  width: 100% !important;
  height: 30px !important;
  background: $main-blue !important;
  border-radius: 3px;
  box-sizing: border-box !important;
  outline: none;
  padding: 0 !important;
}
.filterSearch:hover {
  background-color: $hover-blue !important;
}
.filterClear {
  width: 100% !important;
  height: 30px !important;
  border: 0.75px solid $button-gray !important;
  background-color: $main-white;
  border-radius: 3px;
  outline: none;
  color: $main-gray;
  padding: 0 !important;
}
.filterClear:hover {
  background-color: $main-white !important;
}
.removeAll {
  width: 150px;
  height: 30px;
  border: 0.75px solid $button-gray;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: $main-white;
  transition: all 0.1s ease;
  color: $link-gray;
}
.removeAll:hover {
  color: $red;
  border: 1px solid $red;
}
.removeAll:disabled {
  border-color: $disabled-gray;
  color: $disabled-gray;
}
.removeAll:active {
  color: $red;
  border: 1px solid $red;
  background: rgba(230, 0, 0, 0.2);
}
.editAll {
  width: 150px;
  height: 30px;
  border: 0.75px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: $main-white;
  transition: all 0.1s ease;
  color: $link-gray;
}
.editAll:hover {
  color: $main-blue;
  border: 1px solid $main-blue;
}
.editAll:disabled {
  border-color: $disabled-gray;
  color: $disabled-gray;
}
.editAll:active {
  color: $main-blue;
  border: 1px solid $main-blue;
  background: rgba(18, 132, 255, 0.2);
}
.massEditBtns {
  display: flex;
  flex-direction: column !important;
}
.mass-edit-btn {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}
.add {
  font-size: 14px;
  line-height: 21px;
  color: $main-blue;
  background: transparent;
  border: none;
  outline: none;
  height: 24px;
  text-align: left;
  width: 220px !important;
}
</style>
